<template>
  <modal name="howItWorks" height="auto" :scrollable="true" :width="850" class="modal">
    <div class="text-center">
      <img src="@/assets/images/icons/icons_credit_score.svg" alt="Credit Score" width="auto" height="40" />
      <h3 class="bold">Your credit health check</h3>
      <p class="info-title">Understand what lenders see about your business</p>
      <div class="two-column">
        <div class="column">
          <p>When trying to understand how risky it is to lend to your business, lenders look at your business’ bank account data and account filings.</p>
          <p>Your business credit score and insights help you understand what lenders see when you apply for a loan.</p>
        </div>
        <div class="column">
          <p>
            <strong class="bold">Even if you get a bad grade, you can still qualify for a loan. </strong> Good credit makes it cheaper and easier to borrow, but Swoop will find you
            relevant products regardless.
          </p>
          <p>Where possible, we’ll suggest ways to improve your credit profile, and share useful resources.</p>
        </div>
      </div>
      <button class="btn btn-primary" @click="closeModal">Continue</button>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    modalName: {
      type: String,
      default: 'howItWorks'
    },
    closeModal: {
      type: Function,
      required: true
    }
  }
}
</script>
