<template>
  <div class="carousel-wrapper">
    <em class="icon material-icons" @click="prev()">navigate_before</em>
    <div class="carousel">
      <div class="carousel-overflow">
        <div class="card" v-for="(item, index) in items" :key="item.label" :index="index">
          <div class="title">{{ item.label }}</div>
          <div class="description">{{ item.copy }}</div>
        </div>
      </div>
    </div>
    <em class="icon material-icons" @click="next()">navigate_next</em>
  </div>
</template>

<script>
export default {
  data() {
    return {
      back: false,
      currentIndex: 0,
      currentItem: null,
      items: [
        {
          label: 'Avoid hard credit checks',
          copy:
            'Be careful when applying for financial products, as some of the checks providers perform may impact your credit profile. Swoop will always warn you about any of these ‘hard checks’.'
        },
        {
          label: 'Keep details up-to-date',
          copy: 'Make sure to let Companies House, suppliers, and partners know about any company changes as swiftly as possible, such as a change of registered address.'
        },
        {
          label: 'Pay on-time',
          copy: 'Make sure you pay your bills and debts in a timely manner to avoid negative impacts to your credit profile.'
        },
        {
          label: 'File full accounts',
          copy: 'Filing full accounts (on time), rather than micro entity or abbreviated accounts, can improve your credit score.'
        }
      ]
    }
  },
  methods: {
    next() {
      this.back = false
      if (this.currentIndex === this.items.length - 1) {
        this.currentIndex = 0
      }
      this.currentIndex++
      const removedItem = this.items.shift()
      this.items.push(removedItem)
      this.currentItem = this.items[this.currentIndex]
    },
    prev() {
      this.back = true
      if (this.currentIndex === -1) {
        this.currentIndex = 0
      }
      this.currentIndex--
      const removedItem = this.items.pop()
      this.items.unshift(removedItem)
      this.currentItem = this.items[this.currentIndex]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

.carousel-wrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    vertical-align: middle;
    color: var(--color-primary-300);
    cursor: pointer;

    &:hover {
      color: var(--color-primary-500);
    }
  }
  .carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    .carousel-overflow {
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: opacity 150ms linear;

      .card {
        box-sizing: border-box;
        border: 1px solid var(--color-secondary-50);
        border-radius: 8px;
        background-color: $color-white;
        padding: 30px;
        width: 270px;
        height: 270px;
        margin: 20px;
        transition: background-color 1s;

        &:hover {
          cursor: pointer;
          background-color: var(--color-neutral-50);
        }

        .title {
          font-weight: 600;
          font-size: 18px;
          letter-spacing: -0.4px;
          color: var(--color-primary-500);

          .description {
            font-size: 14px;
            letter-spacing: -0.4px;
            color: var(--color-primary-400);
          }
        }
      }
    }
  }
}
</style>
