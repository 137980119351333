<template>
  <v-chart v-if="option" class="chart" :option="option" autoresize />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import 'echarts'
import { buildTooltip } from '@/utils/chartCurrency'

use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent])

export default {
  name: 'lineChart',
  components: {
    VChart
  },
  props: {
    movingAvg: {
      type: Array,
      required: true
    },
    balance: {
      type: Array,
      required: true
    }
  },
  computed: {
    option() {
      if (this.movingAvg && this.balance) {
        return {
          title: {
            text: 'Balance',
            textStyle: {
              color: '#0F334A',
              fontSize: 22,
              fontWeight: 600,
              letterSpacing: 0.46,
              margin: 90
            }
          },
          legend: {
            animation: true,
            icon: 'circle',
            show: true,
            type: 'scroll',
            align: 'right',
            x: 'right',
            y: 'top',
            border: 20,
            textStyle: {
              fontSize: 15
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              return buildTooltip(params)
            }
          },
          emphasis: {
            focus: 'series',
            blurScope: 'coordinateSystem'
          },
          toolbox: {
            show: true
          },
          xAxis: {
            type: 'category',
            data: this.balance.map(item => {
              const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
              let month = new Date(item.date)
              month = monthNames[month.getMonth()]
              return month
            }),
            offset: 10,
            axisLabel: { color: '#8A9FAE' },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#E9E9E9'
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: function (value) {
                return `£${value / 1000}K`
              },
              color: '#8A9FAE'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#E9E9E9'
              }
            }
          },
          grid: {
            left: '0',
            bottom: '10%',
            width: '100%',
            containLabel: true
          },
          series: [
            {
              name: 'balance',
              type: 'line',
              data: this.balance.map(a => a.value),
              itemStyle: { color: '#CED7DE' },
              symbol: 'circle',
              symbolSize: 7,
              setOption: {
                color: 'red'
              }
            },
            {
              name: 'trend',
              type: 'line',
              data: this.movingAvg.map(a => a.value.toFixed(2)),
              itemStyle: { color: '#0F334A' },
              symbol: 'circle',
              symbolSize: 7
            }
          ]
        }
      }
      return ''
    }
  }
}
</script>
