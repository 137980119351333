import { render, staticRenderFns } from "./creditScore.vue?vue&type=template&id=4ea413d1&scoped=true&"
import script from "./creditScore.vue?vue&type=script&lang=js&"
export * from "./creditScore.vue?vue&type=script&lang=js&"
import style0 from "./creditScore.vue?vue&type=style&index=0&id=4ea413d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea413d1",
  null
  
)

export default component.exports