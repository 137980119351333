<template>
  <div class="double-card">
    <div class="card">
      <div class="credit-description">
        <div v-if="badge">
          <img :src="`${badge && badge.badgeTinyUrl}`" width="80" height="auto" alt="behavioural bagde" />
        </div>
        <div class="description" v-if="rateDetails && rateDetails.grade">
          <span class="sub-title">Behavioural Assessment</span>
          <popover placement="top" trigger="hover click focus" class="popover">
            <em class="icon material-icons">info_outline</em>
            <template #popover>Credit Passport’s behavioural assessment is based on the main business current account activity.</template>
          </popover>
          <div class="credit-quality">{{ creditRatingData.rateDescription }}</div>
          <div>
            {{ rateDetails.grade.behaviouralAssessment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    badge: {
      type: Object
    },
    rateDetails: {
      type: Object
    },
    creditRatingData: {
      type: Object
    }
  }
}
</script>
