<template>
  <div class="card">
    <div class="credit-description credit-description--main-card">
      <div class="badge" v-if="badge && badge.badgeUrl">
        <img :src="`${badge.badgeUrl}`" alt="badge" />
        <div v-if="$config.whitelabel.components.creditScorePlusEnabled">
          <button :disabled="buttonClicked" class="btn btn-primary" @click="downloadReport(companyId)">Download Report<spinner v-if="buttonClicked" /></button>
        </div>
      </div>
      <div class="description">
        <div v-if="creditRatingData && creditRatingData.rateDescription">
          <div class="sub-title">Your grade</div>
          <div class="credit-quality">{{ creditRatingData.rateDescription }}</div>
        </div>
        <div v-if="rateDetails && rateDetails.grade && Array.isArray(rateDetails.grade.actions) && rateDetails.grade.actions.length">
          <p>{{ rateDetails.grade.yourGradeExplainer }}</p>
          <span v-if="creditRatingData && creditRatingData.percentage" class="bold">Your probability of default: </span>
          <span>{{ creditRatingData.percentage }} %</span>
          <popover placement="top" trigger="hover click focus" class="popover">
            <em class="icon material-icons">info_outline</em>
            <template #popover>Probability of Default is the standard measure used by banks and funders in their credit process.</template>
          </popover>
          <div class="additional-info" v-if="rateDetails && rateDetails.grade && rateDetails.grade.whatThisMeans">
            <p class="title">What this means?</p>
            <p>{{ rateDetails.grade.whatThisMeans }}</p>
            <div v-if="rateDetails && rateDetails.grade && rateDetails.grade.actions">
              <div v-for="items in rateDetails.grade.actions" :key="items.label" @click="trackEvent(items)">
                <router-link :to="{ name: `${items.link}` }" class="link">{{ items.label }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'

export default {
  props: {
    badge: {
      type: Object
    },
    creditRatingData: {
      type: Object
    },
    rateDetails: {
      type: Object
    },
    downloadReport: {
      type: Function
    },
    buttonClicked: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    trackEvent(items) {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.CreditScore.CTA_REDIRECT_LINKS,
        eventProperties: {
          'Page name': items.link
        }
      })
    }
  }
}
</script>
