<template>
  <v-chart v-if="option" class="chart" :option="option" autoresize />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import 'echarts'
import { buildTooltip } from '@/utils/chartCurrency'

use([CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent])

export default {
  name: 'barChart',
  components: {
    VChart
  },
  props: {
    cashFlow: {
      type: Array,
      required: true
    }
  },
  computed: {
    option() {
      if (this.cashFlow) {
        return {
          title: {
            text: 'Cashflow',
            textStyle: {
              color: '#0F334A',
              fontSize: 22,
              fontWeight: 600,
              letterSpacing: 0.46,
              margin: 90
            }
          },
          legend: {
            animation: true,
            icon: 'circle',
            show: true,
            align: 'right',
            textStyle: {
              fontSize: 15
            },
            x: 'right',
            y: 'top'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              return buildTooltip(params)
            }
          },
          emphasis: {
            focus: 'series',
            blurScope: 'coordinateSystem'
          },
          grid: {
            left: '0',
            bottom: '10%',
            width: '100%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: { color: '#8A9FAE' },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#E9E9E9'
                }
              },
              data: this.cashFlow.map(item => {
                const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                let month = new Date(item.monthStart)
                month = monthNames[month.getMonth()]
                return month
              }),
              offset: 10
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                formatter: function (value) {
                  return (value / 1000).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) + 'K'
                },
                color: '#8A9FAE'
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#E9E9E9'
                }
              }
            }
          ],
          series: [
            {
              name: 'cash in',
              type: 'bar',
              barWidth: '10%',
              data: this.cashFlow.map(cash => cash.totalCashInPer28Days),
              itemStyle: { color: '#1E517B' },
              barGap: 1
            },
            {
              name: 'cash out',
              type: 'bar',
              barWidth: '10%',
              data: this.cashFlow.map(cash => cash.totalCashOutPer28Days),
              itemStyle: { color: '#979797' }
            }
          ]
        }
      }
      return ''
    }
  }
}
</script>
