<template>
  <div class="card">
    <div class="alert-card">
      <div class="badge">
        <em class="icon material-icons">error_outline</em>
      </div>
      <div class="description">
        <div v-if="alreadyRegistered">
          <div class="title">Your business has previously generated a report with our partner Credit Passport.</div>
          <div>If you have an account with Credit Passport already, you can log in <button class="link" @click="sendToExternalLink(link.sendToCreditPassport)">here.</button></div>
          <div>
            If you think this is mistake, please get in touch with Credit Passport
            <button class="link" @click="sendToExternalLink(link.sendToCreditPassportContactUs)">here.</button>
          </div>
        </div>
        <div v-else-if="bankMandateStatus === 'AuthorisationExpired'">
          <div class="title">The connection to your linked bank account has expired (connections last for 90 days).</div>
          <div>Please re-connect <router-link class="link" :to="{ name: 'integrations' }">here.</router-link></div>
        </div>
        <div v-else-if="error || bankMandateStatus === 'FetchingDataError'">
          <div class="title">We encountered an error while retrieving your credit report, please try again after some time.</div>
          <div>If the error persists, please <button class="link" @click="sendToExternalLink(link.sendToSwoopExperts)">contact our support team</button> for assistance.</div>
        </div>
        <div v-else-if="hasNoBankAccount">
          <div class="title">Unfortunately, there aren’t enough transactions in your bank account to generate a report.</div>
          <div>You can try another account <router-link class="link" :to="{ name: 'integrations' }">here.</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      link: {
        sendToCreditPassportContactUs: 'https://www.creditpassport.com/contact-us',
        sendToCreditPassport: 'https://app.creditpassport.com/#/product/login',
        sendToSwoopExperts: 'https://swoopfunding.com/contact-us'
      }
    }
  },
  props: {
    alreadyRegistered: {
      type: Boolean
    },
    error: {
      type: Boolean
    },
    bankMandateStatus: {
      type: String
    },
    hasNoBankAccount: {
      type: Boolean
    }
  },
  methods: {
    sendToExternalLink(link) {
      window.open(link)
    }
  }
}
</script>
