<template>
  <div>
    <div class="ui active inverted dimmer page-loader" v-if="loading">
      <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
    </div>
    <div v-if="$config.whitelabel.components.creditScoreEnabled && !loading" class="container">
      <!-- Show any error or status -->
      <div v-if="error || bankMandateStatus === 'FetchingDataError' || alreadyRegistered || hasNoBankAccount || bankMandateStatus === 'AuthorisationExpired'">
        <StatusCard :alreadyRegistered="alreadyRegistered" :error="error" :hasNoBankAccount="hasNoBankAccount" :bankMandateStatus="bankMandateStatus" />
      </div>
      <!-- Show page -->
      <div v-else>
        <div v-if="bankMandateStatus === 'ProcessingCompleted'">
          <h1 class="header">Credit health check</h1>
          <p>
            See what lenders see, with credit insights from Credit Passport. <button class="link" @click="openModal()">How it works.</button>
            <HowItWorksModal :closeModal="closeModal" />
          </p>
          <MainGradeCardDetails
            :badge="badge"
            :creditRatingData="creditRatingData"
            :rateDetails="rateDetails"
            :downloadReport="downloadReport"
            :buttonClicked="buttonClicked"
            :companyId="companyId"
          />
          <div class="cards">
            <CompanyInformationCard :companyDetails="companyDetails" />
            <AssesmentCard :badge="badge" :rateDetails="rateDetails" :creditRatingData="creditRatingData" />
          </div>
          <p class="title">Open banking data</p>
          <div class="card">
            <LineChart :cashFlow="cashFlow" :movingAvg="movingAvg" :balance="balance" />
          </div>
          <div class="card">
            <BarChart :cashFlow="cashFlow" />
          </div>
        </div>
        <!-- In progress status until bank integration will be ProcessingCompleted -->
        <div v-else>
          <InProgressCard />
        </div>
      </div>
      <!-- If user cant integrate bank account show this modal -->
      <modal name="unlinkBank" height="auto" :scrollable="true" :width="560">
        <div class="hello-modal text-center">
          <div class="ui text-lg text-center m-b-1">
            <p>We are having trouble with integrating your bank account. Please try again.</p>
          </div>
          <BankIntegrationBtnTrueLayer @click.native="sendToAmplitude()" btnLabel="Link your bank details" @click.prevent="$modal.hide('unlinkBank')" />
        </div>
      </modal>
      <div class="title">Our responsible credit checklist</div>
      <CreditScoreCarousel />
      <div class="footer">
        <div class="align">
          <span>Powered by</span>
          <img src="../../assets/images/credit-passport/cp-logo-blue.svg" width="150" height="auto" alt="logo" />
        </div>
      </div>
      <FooterOutgoingLinks />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankMandateListRoutine, bankGetters } from '@/store/modules/bank/routines'
import { authGetters } from '@/store/modules/auth/routines'
import { getCompany } from '@/api/company'
import { getLoanFormData } from '@product/api/products'
import { generateCreditReport, getCreditReportPdf } from '@/api/creditReport'
import { amplitudeEvents } from '@/utils/constants'
import CompanyInformationCard from './companyInformationCard'
import Spinner from '@/components/Spinner'
import HowItWorksModal from './howItWorksModal.vue'
import gradeDetails from './creditScoreGradeDetails.json'
import CreditScoreCarousel from './creditScoreCarousel.vue'
import BarChart from './barChart.vue'
import LineChart from './lineChart.vue'
import FooterOutgoingLinks from '@/components/FooterOutgoingLinks'
import MainGradeCardDetails from './mainGradeCardDetails.vue'
import AssesmentCard from './assesmentCard.vue'
import StatusCard from './statusCard.vue'
import InProgressCard from './inProgressCard.vue'
import { deleteBankMandate } from '@/api/bankMandate'
import BankIntegrationBtnTrueLayer from '@/components/integrations/BankIntegrationBtnTrueLayer'
import { creditScoreDetailsRoutine, creditScoreGetters } from '@/store/modules/creditScore/routines'

export default {
  name: 'creditScore',
  components: {
    Spinner,
    BarChart,
    LineChart,
    FooterOutgoingLinks,
    CreditScoreCarousel,
    CompanyInformationCard,
    HowItWorksModal,
    MainGradeCardDetails,
    AssesmentCard,
    StatusCard,
    InProgressCard,
    BankIntegrationBtnTrueLayer
  },
  data: () => {
    return {
      cashFlow: [],
      balance: [],
      movingAvg: [],
      companyData: {},
      rating: null,
      analysis: null,
      loanFormData: {},
      badge: null,
      created: null,
      isModalOpen: false,
      loading: true,
      gradeDetails,
      error: false,
      alreadyRegistered: false,
      buttonClicked: false,
      hasNoBankAccount: false,
      bankMandateTimerId: null,
      limit: 60,
      bankMandateId: null
    }
  },

  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      user: authGetters.USER,
      bankMandateStatus: bankGetters.BANK_MANDATE_STATUS,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      creditScoreDetails: creditScoreGetters.CREDIT_SCORE_DETAILS
    }),
    companyDetails() {
      return {
        companyName: this.companyData?.companyName,
        companyNumber: this.companyData?.companyNumber,
        companyAddress: `${this.companyData?.registeredAddressLine} ${this.companyData?.registeredAddressCity} ${this.companyData?.registeredAddressCountry}, ${this.companyData?.registeredAddressPostcode}`,
        incorporated: this.companyData && this.companyData.incorporationDate,
        sector: this.companyData?.detailedSectorsClassification?.[0],
        turnover: this.loanFormData?.turnoverLastYear
      }
    },
    creditRatingData() {
      return {
        rate: this.rating?.fullScore?.label,
        rateDescription: this.rating?.fullScore?.description,
        percentage: this.fixedPercentage
      }
    },
    rateDetails() {
      return { grade: gradeDetails[this.creditRatingData.rate] }
    },
    fixedPercentage() {
      const percentage = this.rating?.fullScore?.pd
      return (parseFloat(percentage) * 100).toFixed(2)
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      setCreditScoreDetails: creditScoreDetailsRoutine.TRIGGER
    }),
    async requestBankMandate() {
      if (this.limit) {
        await this.getBankMandateList(this.companyId) // FetchingData, FetchingDataCompleted, ProcessingCompleted, Failed
      } else if (!this.limit) {
        this.bankMandateId = this.bankMandateList?.[0].bankMandateId
        this.unlinkBank(this.bankMandateId)
        this.$modal.show('unlinkBank')
        return
      }
      return new Promise(resolve => {
        this.bankMandateTimerId = setTimeout(() => {
          this.limit--
          resolve(this.requestBankMandate())
        }, 1000)
      })
    },
    unlinkBank(bankMandateId) {
      return deleteBankMandate(bankMandateId)
        .then(this.requestBankMandate)
        .catch(e => {
          console.error(e)
        })
    },
    async getCreditReport() {
      if (this.creditScoreDetails === null) {
        await generateCreditReport(this.companyId) // POST
        await this.setCreditScoreDetails(this.companyId) // GET
      }
      const { rating, badge, analysis, created } = this.creditScoreDetails
      this.rating = rating
      this.badge = badge
      this.analysis = analysis
      this.created = created
      this.getCashFlowData()
      this.trackPageView()
      this.loading = false
    },
    async getCompanyData() {
      const { data } = await getCompany(this.companyId)
      this.companyData = data
    },
    async getLoanFormData() {
      const { data } = await getLoanFormData(this.companyId)
      this.loanFormData = data
    },
    downloadReport(companyId) {
      this.buttonClicked = true
      getCreditReportPdf(companyId)
        .then(response => {
          this.$ma.trackEvent({ eventType: amplitudeEvents.CreditScore.CTA_DOWNLOAD_REPORT })
          this.pdfLink = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = this.pdfLink
          link.setAttribute('download', 'creditReport.pdf')
          document.body.appendChild(link)
          link.click()
          this.buttonClicked = false
        })
        .catch(error => {
          this.buttonClicked = false
          console.error(error)
        })
    },
    getCashFlowData() {
      this.cashFlow = this.analysis?.incomeOutgoings?.incomeOutgoingsGraphDatas?.map(income => income)
      this.balance = this.analysis?.balanceHistory?.avgBalanceAmountByMonthForLast12M?.map(balance => balance)
      this.movingAvg = this.analysis?.balanceHistory?.movingAvgForLast12M?.map(balance => balance)
    },
    openModal() {
      this.$modal.show('howItWorks')
      this.$ma.trackEvent({ eventType: amplitudeEvents.CreditScore.CTA_HOW_IT_WORKS })
    },
    closeModal() {
      this.$modal.hide('howItWorks', false)
    },
    trackPageView() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.CreditScore.PAGE_VIEW,
        eventProperties: {
          Scale: this.creditRatingData?.rate,
          Grade: this.creditRatingData?.rateDescription,
          'Probability of default': this.creditRatingData?.percentage
        }
      })
    },
    sendToMatchesPage() {
      this.$router.push({ name: 'loan-matches' })
    },
    sendToBankingPage() {
      window.open('https://app.swoopfunding.com/savings/banking')
    }
  },
  async mounted() {
    if (this.$config.whitelabel.components.creditScoreEnabled) {
      try {
        await this.getCreditReport()
        await this.getCompanyData()
        await this.getLoanFormData()

        if (localStorage.getItem('isHowItWorksModal')) {
          this.openModal()
          localStorage.removeItem('isHowItWorksModal')
          localStorage.removeItem('truelayerIntegrationPrevRoute')
        }
      } catch (error) {
        if (error?.response?.status === 422 && error?.response?.data && error?.response?.data?.code) {
          this.alreadyRegistered = true
        } else if (error?.response?.data?.error === 'No Bank Accounts with Transactions found for Company') {
          this.hasNoBankAccount = true
        } else {
          this.error = true
        }
      } finally {
        this.loading = false
      }
    }
  },
  beforeMount() {
    const statuses = ['ProcessingCompleted', 'FetchingDataError', 'AuthorisationExpired']
    if (this.bankMandateStatus && !statuses.includes(this.bankMandateStatus)) {
      return this.requestBankMandate()
    } else if (!this.bankMandateStatus) {
      return this.$router.push({ name: 'credit-health-check' })
    }
  },
  beforeDestroy() {
    clearTimeout(this.bankMandateTimerId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

/deep/ .popover {
  display: inline;
  cursor: pointer;

  .material-icons {
    font-size: 20px;
  }
}
.link {
  display: contents;
}
.container {
  padding: 30px;
  @media only screen and (min-width: $breakpoint-sm-max) {
    padding: 60px;
  }

  /deep/ .title {
    margin-bottom: 10px;
    color: var(--color-primary-500);
    font-size: 18px;
    font-weight: bold;
  }
  .header {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 18px;
    @media only screen and (max-width: $breakpoint-sm-max) {
      font-size: 26px;
    }
  }
  /deep/ .modal {
    .two-column {
      display: flex;
      justify-content: center;
      .column {
        text-align: left;
        max-width: 300px;
        margin: 20px 30px;
      }
    }
  }
  /deep/ .card {
    box-sizing: border-box;
    border: 1px solid var(--color-secondary-50);
    border-radius: 8px;
    background-color: $color-white;
    margin-bottom: 30px;
    padding: 30px;
    @media only screen and (min-width: $breakpoint-sm-max) {
      margin-right: 30px;
    }
    .alert-card {
      margin: 30px 0;
      display: flex;
      .material-icons {
        font-size: 40px;
        margin-right: 20px;
      }
    }
    .credit-description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      @media only screen and (min-width: $breakpoint-sm-max) {
        flex-direction: column;
        @media only screen and (min-width: $breakpoint-md-max) {
          flex-direction: row;
          align-items: flex-start;
        }
      }
      @media only screen and (min-width: $breakpoint-md-max) {
        flex-direction: row;
      }
      &--main-card {
        flex-direction: column;
        @media only screen and (min-width: $breakpoint-md-max) {
          flex-direction: row;
          align-items: flex-start;
        }
      }

      .badge {
        width: 500px;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 300px;
          padding: 30px;
        }

        .btn-primary {
          margin: 30px 0;
          @media only screen and (max-width: $breakpoint-md-max) {
            max-width: 200px;
          }
        }
      }

      .details {
        font-size: 12px;
        @media only screen and (max-width: $breakpoint-md-max) {
          max-width: 300px;
        }
      }
      @media only screen and (min-width: $breakpoint-md-max) {
        flex-direction: row;
      }
      &--main-card {
        flex-direction: column;
        @media only screen and (min-width: $breakpoint-md-max) {
          flex-direction: row;
          align-items: flex-start;
        }
      }

      .badge {
        width: 500px;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 300px;
          padding: 30px;
        }
      }

      .btn-primary {
        margin: 30px;
        width: 300px;

        @media only screen and (max-width: $breakpoint-md-max) {
          max-width: 300px;
        }
      }

      .details {
        font-size: 12px;
        @media only screen and (max-width: $breakpoint-md-max) {
          max-width: 300px;
        }
      }

      .description {
        font-size: 14px;
        width: 75%;
        margin-left: 30px;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 100%;
        }

        .sub-title {
          color: var(--color-primary-200);
          font-weight: 600;
          font-size: 14px;
          margin: 0;
        }
      }
      .additional-info {
        border-radius: 8px;
        background-color: var(--color-primary-100);
        margin: 20px 0;
        padding: 20px;
      }
    }
  }
  /deep/ .credit-quality {
    color: var(--color-primary-500);
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 21px;
    font-family: 'Public Sans';
  }
  /deep/ .cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px 0;
    @media only screen and (min-width: $breakpoint-sm-max) {
      flex-direction: row;
    }

    .card {
      box-sizing: border-box;
      border: 1px solid var(--color-secondary-50);
      border-radius: 8px;
      margin-bottom: 40px;
      padding: 30px;
      @media only screen and (min-width: $breakpoint-sm-max) {
        padding: 3%;
        width: 500px;
      }

      .company-info {
        border-radius: 8px;
        padding: 20px;
        div {
          margin: 10px 0;
        }

        .text {
          margin-right: 10%;
          display: flex;
          @media only screen and (max-width: $breakpoint-md-max) {
            .text-right-mobile {
              margin-right: 10%;
            }
          }
        }
        .info-title {
          color: var(--color-primary-400);
          font-size: 14px;
          font-weight: 600;
        }
        .info-desc {
          color: var(--color-primary-400);
          font-size: 14px;
          text-align: left;
          margin-left: 10px;
        }
      }
    }
  }
  /deep/ .material-icons {
    background-color: $color-white;
    color: var(--color-primary-200);
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
  }
  .chart {
    height: 350px;
  }
  .footer {
    display: flex;
    justify-content: center;
    .align {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
